// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IsoMorphUtils from '../../modules/isoMorphUtils';

// TODO: Ads not currently being used. Possibly remove in the future.
class Ad extends Component {

  componentDidMount () {
    if (!IsoMorphUtils.serverSide) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    const { client, format, slot } = this.props;
    return (
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
      />
    );
  }
}

Ad.propTypes = {
  client: PropTypes.string,
  format: PropTypes.string,
  slot: PropTypes.string
};

Ad.defaultProps = {
  client: 'ca-pub-8468785440937028',
  format: 'auto',
  slot: '1520586190'
};

export default Ad;
