// NPM Requirements
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Ad from '../shared/Ad';

// Selectors
import { getCityConfig } from '../../selectors';

import styles from '../../../stylesheets/public/layouts/SidebarLayout.module.postcss';

class Sidebar extends Component {
  render() {
    const { children, className, hideAd, marginTop, cityConfig } = this.props;

    return (
      <div
        className={cn(className, {
          [styles.Sidebar]: true,
          [styles.Sidebar__marginTop]: marginTop
        })}
      >
        <div className={styles.SidebarBookingCta}>
          <img src="/static/images/badges/cloudtrailer.svg" alt="Food Truck Cloud" width="143" height="143" />
          <p><strong>Think of us for your next event</strong></p>
          <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {cityConfig.vehicle_type.toLowerCase()}s</p>
          <Link
            className="Button"
            to="/catering"
          >
            Food {cityConfig.vehicle_type} Catering
          </Link>
        </div>

        {children}

        {!hideAd && (
          <div className="Advertisement">
            {/* <Ad slot="4474052596" /> */}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

Sidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hideAd: PropTypes.bool,
  marginTop: PropTypes.bool
};

Sidebar.defaultProps = {
  hideAd: false,
  marginTop: false
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
